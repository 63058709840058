.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 0rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
  background: #ffffff;
}
