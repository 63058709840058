.split-view-wrapper {
  height: calc(100vh - 133px);
  background-color: #fff;

  tr {
    cursor: pointer;
  }

  tr.selected {
    background: #8fcd00;
    
    // Alle Zellen außer der letzten deaktivieren
    td {
      pointer-events: none;
    }
    td.exclude-pointer-events {
      pointer-events: auto;
    }
    cursor: default;
  }
}