.layout-sidebar {
  position: fixed;
  width: 210px;
  height: calc(100vh - 132px);
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 70px;
  transition: transform $transitionDuration, left $transitionDuration;
  background-color: var(--surface-overlay);
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid var(--surface-border);
  border-right: 1px solid var(--surface-border);
}

.layout-menu {
  margin: 0;
  padding: 0 10px 0 0;
  list-style-type: none;

  .layout-root-menuitem {
    margin-top: 25px;

    &:first-of-type {
      margin-top: 0;
    }

    > .layout-menuitem-root-text {
      font-size: .857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-900);
      margin: .75rem 0;
      padding-left: 15px;
    }

    > a {
      display: none;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--text-color);
      cursor: pointer;
      padding: 2px 1rem;
      border-radius: $borderRadius;
      transition: background-color $transitionDuration, box-shadow $transitionDuration;
      font-size:14px;
      line-height: 24px;

      .layout-menuitem-icon {
        margin-right: .5rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.active-route {
        font-weight: 700;
        color: var(--primary-color);
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;
      border-radius: $borderRadius;

      li {
        a {
          margin-left: 1rem;
        }

        li {
          a {
            margin-left: 2rem;
          }

          li {
            a {
              margin-left: 2.5rem;
            }

            li {
              a {
                margin-left: 3rem;
              }

              li {
                a {
                  margin-left: 3.5rem;
                }

                li {
                  a {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
