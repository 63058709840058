.#{$prefix}field {
    margin-bottom: $fieldMargin;
    display: flex;
    align-items: center;
}

.#{$prefix}field > label,
.#{$prefix}field-checkbox label {
    display: inline-block;
    margin-bottom: 0;
    width: 100px;
    min-width: 100px;
    font-size: 12px;
    margin-right: 10px;
    overflow-wrap: break-word;
}

.#{$prefix}field > .p-inputtext {
  width: 100%;
}
.#{$prefix}field > p-dropdown .p-inputwrapper,
.#{$prefix}field > p-dropdown {
  width: 100%;
}


.#{$prefix}field > p-calendar .p-calendar .p-inputtext {
  width: 80px;
}

.#{$prefix}field > label + span,
.#{$prefix}field > label + div {
    font-size: 12px;
}

.#{$prefix}field.#{$prefix}grid > label {
    display: flex;
    align-items: center;
}

.#{$prefix}field > small {
    margin-top: $helperTextMargin;
}

.#{$prefix}field.#{$prefix}grid,
.#{$prefix}formgrid.#{$prefix}grid {
    margin-top: 0;
}

.#{$prefix}field.#{$prefix}grid .#{$prefix}col-fixed,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-fixed,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-1, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-1, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-2,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-2, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-3, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-3, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-4, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-4, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-5, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-5, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-6, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-6,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-7, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-7, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-8, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-8, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-9, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-9, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-10, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-10, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-11, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-11, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-12,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-12 {
    padding-top: 0;
    padding-bottom: 0;
}

.#{$prefix}formgroup-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.#{$prefix}formgroup-inline .#{$prefix}field,
.#{$prefix}formgroup-inline .#{$prefix}field-checkbox,
.#{$prefix}formgroup-inline .#{$prefix}field-radiobutton {
    margin-right: $fieldMargin;
}

.#{$prefix}formgroup-inline .#{$prefix}field > label,
.#{$prefix}formgroup-inline .#{$prefix}field-checkbox > label,
.#{$prefix}formgroup-inline .#{$prefix}field-radiobutton > label {
    margin-right: $fieldLabelMargin;
    margin-bottom: 0;
}

.#{$prefix}field-checkbox,
.#{$prefix}field-radiobutton {
    //margin-bottom: $fieldMargin;
    display: flex;
    align-items: center;
}

.#{$prefix}field-checkbox > label,
.#{$prefix}field-radiobutton > label {
    //margin-right: $fieldLabelMargin;
    line-height: 1;
}
